import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import ContactForm from '@components/forms/contact-form'
import Accordion from 'react-bootstrap/Accordion'
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'

const ContactPage = () => {
  return (
    <Layout pageTitle="Contact Us">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../media/contact_hero.jpeg"
                alt=""
                aspectRatio={3.75 / 1}
                objectPosition="center top"
                layout="fullWidth" />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>CONTACT</h2>
                <h1>Contact Us</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid="xxl" className="section">
        <Row xl={2} lg={2} md={1} sm={1} xs={1}>
          <Col md>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Main Office</Accordion.Header>
                <Accordion.Body className='ps-5'>
                        <div>
                          <span className="bold-1">t. </span>
                          <a className="display-6" href="tel:18664937782">1.866.493.7782</a>
                          <p>
                          <a href="tel:5197520789" className='ps-3'>519.752.0789 <span className="text-muted fs-6">(auto attendant)</span></a>
                          </p>
                        </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">e. </span>
                          <a className="bold-2" href="mailto:info@wessuc.com">info@wessuc.com</a>
                        </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Sales</Accordion.Header>
                <Accordion.Body>
                  <Container fluid="xxl">
                    <ListGroup variant='flush'>  
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Bill Buist | </span>
                          <a className="bold-2" href="mailto:bill.buist@wessuc.com">bill.buist@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Director of Sales
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789">519.752.0789</a>
                        </div>
                        <div>
                          <span className="bold-1">m. </span>
                          <a href="tel:9057309624">905.730.9624</a>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Phil Cron | </span>
                          <a className="bold-2" href="mailto:phil@wessuc.com">phil@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Director of Business Development
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789x1014">519.752.0789 x1014</a>
                        </div>
                        <div>
                          <span className="bold-1">m. </span>
                          <a href="tel:5197171437">519.717.1437</a>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Lorne McCallum | </span>
                          <a className="bold-2" href="mailto:lorne@wessuc.com">lorne@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Northern &amp; Eastern Ontario Regional Sales Manager
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>9523 County Rd. 10 RR#2, New Lowel, ON  L0M 1N0</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789x1007">519.752.0789 x2000</a>
                        </div>
                        <div>
                          <span className="bold-1">m. </span>
                          <a href="tel:2896836796">289.683.6796</a>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Brett Stiles | </span>
                          <a className="bold-2" href="mailto:brett.stiles@wessuc.com">brett.stiles@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Western Ontario Regional Sales Manager
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789x1007">519.752.0789 x1007</a>
                        </div>
                        <div>
                          <span className="bold-1">m. </span>
                          <a href="tel:5194654086">519.465.4086</a>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Sjouke Dykstra | </span>
                          <a className="bold-2" href="mailto:sjouke@wessuc.com">sjouke@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Southern Ontario Regional Sales Manager
                        </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789x1011">519.752.0789 x1011</a>
                        </div>
                        <div>
                          <span className="bold-1">m. </span>
                          <a href="tel:9059816758">905.981.6758</a>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Human Resources</Accordion.Header>
                <Accordion.Body>
                  <Container fluid="xxl">
                    <ListGroup variant='flush'>  
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Rob Dalingwater | </span>
                          <a className="bold-2" href="mailto:rob.dalingwater@wessuc.com">rob.dalingwater@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Director of Human Resources and Safety
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789">519.752.0789 x1028</a>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <div>
                          <span className="bold-1">Eric Elgersma | </span>
                          <a className="bold-2" href="mailto:eric.elgersma@wessuc.com">eric.elgersma@wessuc.com</a>
                        </div>
                        <div className='text-muted'>
                          Human Resources Specialist
                          </div>
                        <div>
                          <span className="bold-1">a. </span>
                          <span>1693 Colborne St. E., Brantford, ON  N3T 5L4</span>
                        </div>
                        <div>
                          <span className="bold-1">t. </span>
                          <a href="tel:18664937782">1.866.493.7782</a>
                          <span className="bold-1"> | </span>
                          <a href="tel:5197520789">519.752.0789 x1003</a>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md>
            <ContactForm />
          </Col>
        </Row>
      </Container>
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <iframe
              className="iframe-container"
              title="Wessuc Brantford Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2910.026863410156!2d-80.1320983482703!3d43.16696027903874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882c625a3bfdcb25%3A0xf9b16d51e97c4062!2s1693%20Colborne%20St%20E%2C%20Brantford%2C%20ON%20N3T%205L4!5e0!3m2!1sen!2sca!4v1629748396895!5m2!1sen!2sca"
              width="600" height="450" allowfullscreen="" loading="lazy"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContactPage