import * as React from 'react'

const ContactForm = () => {
  return (
    <div>
      <iframe
        id="contact-form"
        className="placeholder-iframe" 
        width="100%"
        frameborder="0"
        title="Contact Form"
        src='https://forms.zohopublic.com/wessucinc/form/WebsitePlaceholder/formperma/F7jDNuUDLYnkxRTTD2Be6M3239DfWf6TJnd9POB9kZQ'>
      </iframe>
    </div>
  )
}

export default ContactForm